export default {
    印前处理: '印前处理',
    文件上传中: '文件上传中',
    上传文件: '上传文件',
    版面参数: '版面参数',
    是否推送审批链接至邮箱: '是否推送审批链接至邮箱',
    不推送: '不推送',
    推送: '推送',
    邮箱: '邮箱',
    请输入正确的邮箱: '请输入正确的邮箱',
    是否推送链接到我的微信: '是否推送链接到我的微信',
    标记: '标记',
    自定义值: '自定义值',
    宽度: '宽度',
    最大值: '最大值',
    最小值: '最小值',
    高度: '高度',
    咬口: '咬口',
    数量: '数量',
    名称: '名称',
    刀模: '刀模',
    选择微穴种子文件: '选择微穴种子文件',
    网墙宽度: '网墙宽度',
    最小网点: '最小网点',
    是否使用网墙微穴: '是否使用网墙微穴',
    网墙微穴种子文件: '网墙微穴种子文件',
    是否使用切边微穴: '是否使用切边微穴',
    切边微穴种子文件: '切边微穴种子文件',
    切边高度: '切边高度',
    不使用: '不使用',
    使用: '使用',
    模型: '模型',
    横向数量: '横向数量',
    横向间距: '横向间距',
    纵向数量: '纵向数量',
    纵向间距: '纵向间距',
    方向: '方向',
    缺省出血: '缺省出血',
    单元间距: '单元间距',
    补偿曲线: '补偿曲线',
    分辨率: '分辨率',
    网点形状: '网点形状',
    加网线数: '加网线数',
    最小网点尺寸: '最小网点尺寸',
    过度点: '过度点',
    网点角度: '网点角度',
    水平变形率: '水平变形率',
    垂直变形率: '垂直变形率',
    精度: '精度',
    陷印距离: '陷印距离',
    输出模式: '输出模式',
    每个文件页数: '每个文件页数',
    从哪行数据开始: '从哪行数据开始',
    执行数据行数: '执行数据行数',
    表示全部: '表示全部',
    请选择至少一项: '请选择至少一项',
    提交成功: '提交成功',
    开始处理: '开始处理',
    自定义网点角度: '自定义网点角度',
    新增网点角度: '新增网点角度',
    度: '度',
    otherDcolor: '其他专色默认为%1',
    删除成功: '删除成功',
    单摸: '单摸',
    背旋转方向: '背旋转方向',
    正旋转方向: '正旋转方向',
    版长: '版长',
    周长: '周长',
    左走空: '左走空',
    左跟踪宽度: '左跟踪宽度',
    左出血: '左出血',
    背: '背',
    底: '底',
    正: '正',
    上出血: '上出血',
    下出血: '下出血',
    右走空: '右走空',
    右跟踪宽度: '右跟踪宽度',
    是否制袋: '是否制袋',
    数量要求: '数量要求',
    规格尺寸: '规格尺寸',
    袋型: '袋型',
    开口方向: '开口方向',
    封边宽度: '封边宽度',
    撕口位置: '撕口位置',
    圆角: '圆角',
    双切刀: '双切刀',
    吊挂孔: '吊挂孔',
    孔位置: '孔位置',
    烫刀形状: '烫刀形状',
    拉链类型: '拉链类型',
    模切要求: '模切要求',
    拉链位置: '拉链位置',
    尺寸确认: '尺寸确认',
    封口牢度: '封口牢度',
    漏气: '漏气',
    图文制作: '图文制作',
    印刷要求: '印刷要求',
    细节确认: '细节确认',
    制袋操作: '制袋操作',
    完成时间: '完成时间',
    是否制作: '是否制作',
    图稿标准: '图稿标准',
    稿件确认方式: '稿件确认方式',
    色样标准: '色样标准',
    色样确认方式: '色样确认方式',
    拼数: '拼数',
    单元规格: '单元规格',
    其他要求: '其他要求',
    稿件名称: '稿件名称',
    印前操作: '印前操作',
    是否印刷: '是否印刷',
    自带料: '自带料',
    自带料位置: '自带料位置',
    '材料/规格': '材料/规格',
    有效印宽: '有效印宽',
    出卷方向: '出卷方向',
    印刷数量: '印刷数量',
    头出: '头出',
    '头出 90度': '头出 90度',
    尾出: '尾出',
    '尾出 90度': '尾出 90度',
    无要求: '无要求',
    检索半色调: '检索半色调',
    文件数量不能大于: '文件数量不能大于',
    文件数量必须大于: '文件数量必须大于',
    描述文本: '描述文本',
    拼版模式: '拼版模式',
    传统: '传统',
    数码: '数码',
    出血距离: '出血距离',
    页面范围: '页面范围',
    全部: '全部',
    第一页: '第一页',
    选定范围: '选定范围',
    格式: '格式',
    高分辨率图像: '高分辨率图像',
    检查蒙版: '检查蒙版',
    降精度: '降精度',
    请选择: '请选择',
    正常: '正常',
    警告: '警告',
    错误: '错误',
    高分辨率位图: '高分辨率位图',
    低分辨率图像: '低分辨率图像',
    低分辨率位图: '低分辨率位图',
    检测条形码: '检测条形码',
    识别: '识别',
    混合油墨条形码: '混合油墨条形码',
    非嵌入字体: '非嵌入字体',
    缺少字体符号: '缺少字体符号',
    子集字体: '子集字体',
    混合油墨文本: '混合油墨文本',
    文本对象: '文本对象',
    矢量化: '矢量化',
    字体大小: '字体大小',
    最少: '最少',
    等于: '等于',
    最多: '最多',
    至少包含分色数量: '至少包含分色数量',
    分析文本对象: '分析文本对象',
    RGB对象: 'RGB对象',
    分析图像对象: '分析图像对象',
    分析其他对象: '分析其他对象',
    转换为CMYK: '转换为CMYK',
    lab对象: 'lab对象',
    灰色对象: '灰色对象',
    仅在CMYK图像中: '仅在CMYK图像中',
    仅在图像中: '仅在图像中',
    在所有对象中: '在所有对象中',
    移除: '移除',
    镂空黑色物体: '镂空黑色物体',
    进行叠印: '进行叠印',
    镂空黑色文本: '镂空黑色文本',
    最大宽度: '最大宽度',
    镂空黑色边框: '镂空黑色边框',
    镂空黑色矩形: '镂空黑色矩形',
    分析填充: '分析填充',
    分析边框: '分析边框',
    镂空的技术油墨: '镂空的技术油墨',
    镂空的光油墨: '镂空的光油墨',
    镂空的不透明油墨: '镂空的不透明油墨',
    叠印的白色物体: '叠印的白色物体',
    移除叠印: '移除叠印',
    混合油墨对象: '混合油墨对象',
    '技术/非技术油墨混合': '技术/非技术油墨混合',
    未使用的分色: '未使用的分色',
    去除: '去除',
    颜色: '颜色',
    至: '至',
    加网物件: '加网物件',
    移除加网: '移除加网',
    XMP加网信息: 'XMP加网信息',
    设置为默认值: '设置为默认值',
    未加网对象: '未加网对象',
    查找未加网的对象: '查找未加网的对象',
    查找缺少分色项的加网: '查找缺少分色项的加网',
    查找缺少分色的未加网对象: '查找缺少分色的未加网对象',
    边框: '边框',
    分色计数: '分色计数',
    笔画宽度: '笔画宽度',
    修复: '修复',
    开放路径: '开放路径',
    关闭: '关闭',
    建议的替换文案: '建议的替换文案',
    替换: '替换',
    印版宽度: '印版宽度',
    印版高度: '印版高度',
    纸张宽度: '纸张宽度',
    纸张高度: '纸张高度',
    纸张参考点: '纸张参考点',
    上左: '上左',
    上中: '上中',
    上右: '上右',
    左: '左',
    中心: '中心',
    右: '右',
    下左: '下左',
    下中: '下中',
    下右: '下右',
    纸张水平偏移: '纸张水平偏移',
    纸张垂直偏移: '纸张垂直偏移',
    拼版参考点: '拼版参考点',
    拼版水平偏移: '拼版水平偏移',
    拼版垂直偏移: '拼版垂直偏移',
    左间距: '左间距',
    右间距: '右间距',
    上间距: '上间距',
    下间距: '下间距',
    单模文件页码: '单模文件页码',
    裁切路径: '裁切路径',
    媒体框: '媒体框',
    裁剪框: '裁剪框',
    修剪框: '修剪框',
    出血框: '出血框',
    编辑框: '编辑框',
    根据单模设置: '根据单模设置',
    单模方向: '单模方向',
    上: '上',
    下: '下',
    镜像: '镜像',
    左右: '左右',
    上下: '上下',
    水平拼数: '水平拼数',
    垂直拼数: '垂直拼数',
    水平偏移: '水平偏移',
    垂直偏移: '垂直偏移',
    水平间隙: '水平间隙',
    垂直间隙: '垂直间隙',
    交错方向: '交错方向',
    交错偏移距离: '交错偏移距离',
    交错偏移比例: '交错偏移比例',
    重新排列: '重新排列',
    列: '列',
    将额外的台版添加到: '将额外的台版添加到',
    奇数: '奇数',
    偶数: '偶数',
    包括部分一键启动: '包括部分一键启动',
    交错转向: '交错转向',
    头对头: '头对头',
    头对尾: '头对尾',
    尾对尾: '尾对尾',
    尾对头: '尾对头',
    交错行间距类型: '交错行间距类型',
    行的间隙: '行的间隙',
    列的间隙: '列的间隙',
    交错行间距个数: '交错行间距个数',
    交错行间距长度: '交错行间距长度',
    生成单模号: '生成单模号',
    单模编号前缀: '单模编号前缀',
    单模编号样式: '单模编号样式',
    数字: '数字',
    罗马数字: '罗马数字',
    单模编号起始位置: '单模编号起始位置',
    左上: '左上',
    右上: '右上',
    右下: '右下',
    左下: '左下',
    单模编号排列方向: '单模编号排列方向',
    横向: '横向',
    横向蛇形: '横向蛇形',
    竖向: '竖向',
    竖向蛇形: '竖向蛇形',
    出血: '出血',
    获取单模的出血距离: '获取单模的出血距离',
    水平无缝连接: '水平无缝连接',
    垂直无缝连接: '垂直无缝连接',
    从第一个区块开始应用变量: '从第一个区块开始应用变量',
    到黑色: '到黑色',
    到斑点: '到斑点',
    到不透明: '到不透明',
    重叠: '重叠',
    反向陷印: '反向陷印',
    强度: '强度',
    回拉: '回拉',
    允许所有: '允许所有',
    压平部分: '压平部分',
    黑色压平部分: '黑色压平部分',
    无: '无',
    回拉类型: '回拉类型',
    两者: '两者',
    单个: '单个',
    只剩一个色: '只剩一个色',
    回拉来源: '回拉来源',
    所有: '所有',
    仅限白色: '仅限白色',
    回拉距离: '回拉距离',
    边角: '边角',
    截断: '截断',
    允许对象相关陷印: '允许对象相关陷印',
    保持对象形状: '保持对象形状',
    斜角: '斜角',
    尖角: '尖角',
    直角: '直角',
    中心截断: '中心截断',
    边缘截断: '边缘截断'
}
