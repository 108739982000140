export default {
    '1010102': '预检',
    '1010103': '陷印',
    '1010104': '对比',
    '1010105': '拼版',
    '1010106': 'RIP',
    '1010107': 'LenToTIFF',
    '1010108': '审批',
    '1010109': '智能柔印',
    '1010110': '混拼',
    '1010111': '画质增强',
    '1010112': '3D光线追踪',
    '1010113': '色彩转换',
    '1010114': 'VDP',
    '1010115': '软包订单拼版',
    '1010116': '多页PDF拆分',
    '1010117': '刀模拼版',
    '1010118': 'Tiff精度转换',
    '1010119': '自动出血',
    '1010120': 'XML拼版',
    '1010121': '书刊拼版',
    '1010122': '一致性检查',
    '1010151': '智能抠图',
    '1010153': '生成相似图',
    '1010154': '智能扩图',
    '1010156': '图转线稿',
    '1010157': '文生图',
    '1010199': 'G2P',

    '00_原件': '原件',
    '01_预检': '预检',
    预检文件: '预检文件',
    预检报告: '预检报告',
    '02_陷印': '陷印',
    '03_对比': '对比',
    对比记录: '对比记录',
    对比报告: '对比报告',
    '04_拼版': '拼版',
    '05_RIP': 'RIP',
    '06_LenToTIFF': 'LenToTIFF',
    '07_审批': '审批',
    '08_智能柔印': '智能柔印',
    '09_混拼': '混拼',
    '10_画质增强': '画质增强',
    '11_3D光线追踪': '3D光线追踪',
    '12_色彩转换': '色彩转换',
    '13_VDP': 'VDP',
    '14_软包订单拼版': '软包订单拼版',
    '16_多页PDF拆分': '多页PDF拆分',
    '17_刀模拼版': '刀模拼版',
    '18_Tiff精度转换': 'Tiff精度转换',
    '19_自动出血': '自动出血',
    '20_XML拼版': 'XML拼版',
    '21_书刊拼版': '书刊拼版',
    '22_一致性检查': '一致性检查',
    '51_智能抠图': '智能抠图',
    '53_图像联想': '生成相似图',
    '54_智能扩图': '智能扩图',
    '55_智能擦除': '智能擦除',
    '56_线稿': '图转线稿',
    '57_文生图': '文生图',
    '99_G2P': 'G2P',

    '1010102.desc': '自动检测文件合规性，生成详尽的报告。',
    '1010103.desc': '补偿的对位误差，防止出现未印刷的空隙。',
    '1010104.desc': '快速检测文件变化，并生成清晰的对比报告。',
    '1010105.desc': '轻松实现对文件的灵活拼贴和混排。',
    '1010106.desc': '进行自动化分色处理和个性化参数设置。',
    '1010107.desc': '便捷地将Len格式文件转换为TIFF格式。',
    '1010108.desc': '支持多渠道抄送确认，并保存在云端。',
    '1010109.desc': '优化传统柔版印刷过程。',
    '1010110.desc': '轻松实现文件的多元化排列和组合。',
    '1010111.desc': '提升印前文件的图像质量，确保印刷品质更为清晰。',
    '1010112.desc': '对印前文件的三维图像高清光线渲染。',
    '1010113.desc': '保证印刷品的色彩准确性和一致性。',
    '1010114.desc': '可变数据实现每个打印项的个性化和定制。',
    '1010115.desc': '软包装订单及拼版自动化。',
    '1010116.desc': '把PDF文件，每页拆分成独立的PDF文件。',
    '1010117.desc': '按照输入的刀模文件，完成拼版。',
    '1010118.desc': 'Tiff文件精度值按照输入参数转换。',
    '1010119.desc': '标签文件根据输入参数自动出血。',
    '1010120.desc': '轻松实现对文件的灵活拼贴和混排。',
    '1010121.desc': '书刊拼版',
    '1010122.desc': '一致性检查',
    '1010151.desc': 'AI识别图像中的主要实体，完成一键抠图。',
    '1010153.desc': '根据输入的图片，AI生成相似的图片。',
    '1010154.desc': '根据输入的图像，智能扩展生成图片。',
    '1010156.desc': '根据输入的图像，生成线稿。',
    '1010157.desc': '根据输入的文本，生成图片',
    '1010199.desc': 'G2P',

    'program.StatusMap.create': '已创建',
    'program.StatusMap.doing': '处理中',
    'program.StatusMap.failure': '失败',
    'program.StatusMap.success': '成功',
    'program.StatusMap.timeout': '已超时',
    'program.StatusMap.stop': '流程终止',
    'program.StatusMap.audit_success': '审核通过',
    'program.StatusMap.audit_failure': '审核拒绝',
    'program.StatusMap.send_success': '发送成功'
}
