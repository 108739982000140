export default {
    印前处理: 'Prepress Processes ',
    文件上传中: 'File upload in progress',
    上传文件: 'Upload files',
    版面参数: 'Layout parameters',
    是否推送审批链接至邮箱: 'Do you want to push the approval link to the email',
    不推送: 'no',
    推送: 'yes',
    邮箱: 'mailbox',
    请输入正确的邮箱: 'Please enter the correct email address',
    是否推送链接到我的微信: 'Do you want to push a link to my WeChat account',
    标记: 'mark',
    自定义值: 'Custom value',
    宽度: 'width',
    最大值: 'Max',
    最小值: 'Min',
    高度: 'height',
    咬口: 'Bite',
    数量: 'quantity',
    名称: 'name',
    刀模: 'Knife mold',
    选择微穴种子文件: 'Select micro hole seed file',
    网墙宽度: 'Mesh wall width',
    最小网点: 'Minimum network point',
    是否使用网墙微穴: 'Whether to use mesh wall micro holes',
    网墙微穴种子文件: 'Net wall micro hole seed file',
    是否使用切边微穴: 'Whether to use trimming micro holes',
    切边微穴种子文件: 'Edge trimming micro hole seed file',
    切边高度: 'Trimming height',
    不使用: 'no',
    使用: 'yes',
    模型: 'model',
    横向数量: 'Horizontal quantity',
    横向间距: 'Horizontal spacing',
    纵向数量: 'Vertical quantity',
    纵向间距: 'Vertical spacing',
    方向: 'direction',
    缺省出血: 'Default bleeding',
    单元间距: 'Unit spacing',
    补偿曲线: 'Compensation curve',
    分辨率: 'resolving power',
    网点形状: 'dot shape',
    加网线数: 'screen frequency',
    最小网点尺寸: 'Minimum dot size',
    过度点: 'Transition point',
    网点角度: 'Branch angle',
    水平变形率: 'Horizontal deformation rate',
    垂直变形率: 'Vertical deformation rate',
    精度: 'accuracy',
    陷印距离: 'Trapping distance',
    输出模式: 'Output mode',
    每个文件页数: 'Number of pages per file',
    从哪行数据开始: 'Starting from which line of data',
    执行数据行数: 'Number of executed data rows',
    表示全部: 'Indicates all',
    请选择至少一项: 'Please select at least one item',
    提交成功: 'Submitted successfully',
    开始处理: 'Start processing',
    自定义网点角度: 'Custom branch angle',
    新增网点角度: 'New branch angle',
    度: 'degree',
    otherDcolor: 'Other spot colors default to %1',
    删除成功: 'Delete successful',
    单摸: 'Single touch',
    背旋转方向: 'Back rotation direction',
    正旋转方向: 'Positive rotation direction',
    版长: 'Version length',
    周长: 'perimeter',
    左走空: 'Left empty space',
    左跟踪宽度: 'Left tracking width',
    左出血: 'Left hemorrhage',
    背: 'Back',
    底: 'bottom',
    正: 'just',
    上出血: 'Upper bleeding',
    下出血: 'Lower hemorrhage',
    右走空: 'Right walk empty',
    右跟踪宽度: 'Right tracking width',
    是否制袋: 'Whether to make bags',
    数量要求: 'Quantity requirements',
    规格尺寸: 'Specifications and dimensions',
    袋型: 'Bag type',
    开口方向: 'Opening direction',
    封边宽度: 'Edge width',
    撕口位置: 'Tear position',
    圆角: 'fillet',
    双切刀: 'Double cutter',
    吊挂孔: 'Hanging hole',
    孔位置: 'Hole position',
    烫刀形状: 'Hot knife shape',
    拉链类型: 'Zipper type',
    模切要求: 'Die cutting requirements',
    拉链位置: 'zipper line',
    尺寸确认: 'Size confirmation',
    封口牢度: 'Sealing fastness',
    漏气: 'Air leakage',
    图文制作: 'Image and text production',
    印刷要求: 'Printing requirements',
    细节确认: 'Details confirmation',
    制袋操作: 'Bag making operation',
    完成时间: 'Completion time',
    是否制作: 'Is it made',
    图稿标准: 'Draft standards',
    稿件确认方式: 'Manuscript confirmation method',
    色样标准: 'Color sample standard',
    色样确认方式: 'Color sample confirmation method',
    拼数: 'Pinyin',
    单元规格: 'Unit specifications',
    其他要求: 'Other requirements',
    稿件名称: 'Title of the manuscript',
    印前操作: 'Prepress operation',
    是否印刷: 'Whether to print',
    自带料: 'Comes with materials',
    自带料位置: 'Self contained material position',
    '材料/规格': 'Material/Specification',
    有效印宽: 'Effective print width',
    出卷方向: 'Output direction',
    印刷数量: 'Printing quantity',
    头出: 'Head out',
    '头出 90度': '90 degrees head out',
    尾出: 'Tail exit',
    '尾出 90度': 'Tail out 90 degrees',
    无要求: 'No requirements',
    检索半色调: 'Retrieval halftone',
    文件数量不能大于: 'The number of files cannot be greater than',
    文件数量必须大于: 'The number of files must be greater than',
    描述文本: 'Description text',
    拼版模式: 'Mosaic mode',
    传统: 'tradition',
    数码: 'Digital code',
    出血距离: 'Bleeding distance',
    页面范围: 'Page range',
    全部: 'All',
    第一页: 'First page',
    选定范围: 'Selection range',
    格式: 'format',
    高分辨率图像: 'High resolution image',
    检查蒙版: 'Check mask',
    降精度: 'Precision reduction',
    请选择: 'Please select',
    正常: 'normal',
    警告: 'warn',
    错误: 'mistake',
    高分辨率位图: 'High resolution bitmap',
    低分辨率图像: 'Low resolution image',
    低分辨率位图: 'Low resolution bitmap',
    检测条形码: 'Detection bar code',
    识别: 'recognize',
    混合油墨条形码: 'Mixed ink bar code',
    非嵌入字体: 'Unembedded font',
    缺少字体符号: 'Missing font symbol',
    子集字体: 'Subset font',
    混合油墨文本: 'Mixed ink text',
    文本对象: 'Text object',
    矢量化: 'vectorization',
    字体大小: 'Font size',
    最少: 'minimum',
    等于: 'Equal to',
    最多: 'At most',
    至少包含分色数量: 'Contains at least the number of separations',
    分析文本对象: 'Analyze text object',
    RGB对象: 'RGB object',
    分析图像对象: 'Analyze image objects',
    分析其他对象: 'Analyze other objects',
    转换为CMYK: 'Convert to CMYK',
    lab对象: 'lab object',
    灰色对象: 'Gray object',
    仅在CMYK图像中: 'Only in CMYK images',
    仅在图像中: 'Only in the image',
    在所有对象中: 'In all objects',
    移除: 'Remove',
    镂空黑色物体: 'Hollow black object',
    进行叠印: 'overprint',
    镂空黑色文本: 'Hollow black text',
    最大宽度: 'Maximum width',
    镂空黑色边框: 'Hollow black border',
    镂空黑色矩形: 'Hollow black rectangle',
    分析填充: 'Analytical filling',
    分析边框: 'Analysis border',
    镂空的技术油墨: 'Hollow technical ink',
    镂空的光油墨: 'Hollow light ink',
    镂空的不透明油墨: 'Hollow opaque ink',
    叠印的白色物体: 'Overprinted white objects',
    移除叠印: 'Remove overprint',
    混合油墨对象: 'Mixed ink object',
    '技术/非技术油墨混合': 'Technical/non-technical ink mix',
    未使用的分色: 'Unused color separation',
    去除: 'remove',
    颜色: 'colour',
    至: 'to',
    加网物件: 'Netted object',
    移除加网: 'Remove screening',
    XMP加网信息: 'XMP networking information',
    设置为默认值: 'Set to the default value',
    未加网对象: 'Unnetted object',
    查找未加网的对象: 'Finds an unnetted object',
    查找缺少分色项的加网: 'Finds mesh that lacks a color separation',
    查找缺少分色的未加网对象: 'Finds an unscreened object that lacks color separation',
    边框: 'border',
    分色计数: 'Color separation count',
    笔画宽度: 'Stroke width',
    修复: 'Repair',
    开放路径: 'Open path',
    关闭: 'Off',
    建议的替换文案: 'Suggested replacement copy',
    替换: 'Replace',
    印版宽度: 'Plate width',
    印版高度: 'Plate height',
    纸张宽度: 'Paper width',
    纸张高度: 'Paper height',
    纸张参考点: 'Paper reference point',
    上左: 'Top left',
    上中: 'Upper middle school',
    上右: 'Top right',
    左: 'On the left',
    中心: 'Centre',
    右: 'right',
    下左: 'Lower left',
    下中: 'Lower median',
    下右: 'Lower right',
    纸张水平偏移: 'Paper horizontal shift',
    纸张垂直偏移: 'Paper vertical offset',
    拼版参考点: 'Mosaic reference point',
    拼版水平偏移: 'Patchwork horizontal offset',
    拼版垂直偏移: 'Mosaic vertical offset',
    左间距: 'Left space',
    右间距: 'Right margin',
    上间距: 'Upper space',
    下间距: 'Lower space',
    单模文件页码: 'Page number of single-mode document',
    裁切路径: 'Crop path',
    媒体框: 'Media frame',
    裁剪框: 'Crop box',
    修剪框: 'Trim frame',
    出血框: 'Bleed frame',
    编辑框: 'Edit box',
    根据单模设置: 'Set according to single mode',
    单模方向: 'Single mode direction',
    上: 'Up',
    下: 'Down',
    镜像: 'Mirror image',
    左右: 'around',
    上下: 'Up and down',
    水平拼数: 'Horizontal spelling',
    垂直拼数: 'Vertical spelling',
    水平偏移: 'Horizontal migration',
    垂直偏移: 'Vertical migration',
    水平间隙: 'Horizontal clearance',
    垂直间隙: 'Vertical clearance',
    交错方向: 'Alternate direction',
    交错偏移距离: 'Staggered offset distance',
    交错偏移比例: 'Interleaved migration ratio',
    重新排列: 'rearrange',
    列: 'Column',
    将额外的台版添加到: 'Add additional console versions to',
    奇数: 'Odd number',
    偶数: 'Even number',
    包括部分一键启动: 'Includes partial one-click start',
    交错转向: 'Staggered steering',
    头对头: 'Head to head',
    头对尾: 'Head to tail',
    尾对尾: 'tail to tail',
    尾对头: 'tail to head',
    交错行间距类型: 'Interleaved line spacing type',
    行的间隙: 'Row gap',
    列的间隙: 'Column gap',
    交错行间距个数: 'Number of interleaved line spacing',
    交错行间距长度: 'Interlaced line spacing length',
    生成单模号: 'Generate a single mode number',
    单模编号前缀: 'Single mode number prefix',
    单模编号样式: 'Single mode numbering style',
    数字: 'figure',
    罗马数字: 'Roman numeral',
    单模编号起始位置: 'Single mode number start position',
    左上: 'Upper left',
    右上: 'Upper right',
    右下: 'Lower right',
    左下: 'Lower left',
    单模编号排列方向: 'Single mode numbering arrangement direction',
    横向: 'Landscape orientation',
    横向蛇形: 'Transverse serpentine',
    竖向: 'vertical',
    竖向蛇形: 'Vertical serpentine',
    出血: 'hemorrhage',
    获取单模的出血距离: 'The bleeding distance of a single mode was obtained',
    水平无缝连接: 'Horizontal seamless connection',
    垂直无缝连接: 'Vertical seamless connection',
    从第一个区块开始应用变量: 'Apply the variable from the first block',
    到黑色: 'To black',
    到斑点: 'To spot',
    到不透明: 'To opaque',
    重叠: 'overlap',
    反向陷印: 'Reverse trapping',
    强度: 'intensity',
    回拉: 'Pull back',
    允许所有: 'Allow all',
    压平部分: 'Flattening part',
    黑色压平部分: 'Black flatten part',
    无: 'There is no',
    回拉类型: 'Pullback type',
    两者: 'both',
    单个: 'single',
    只剩一个色: 'Only one color left',
    回拉来源: 'Pullback source',
    所有: 'own',
    仅限白色: 'White only',
    回拉距离: 'Pull-back distance',
    边角: 'corner',
    截断: 'truncation',
    允许对象相关陷印: 'Allows object-related trapping',
    保持对象形状: 'Hold object shape',
    斜角: 'bevel',
    尖角: 'miter',
    直角: 'square',
    中心截断: 'center',
    边缘截断: 'edge'
}
