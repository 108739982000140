<script setup lang="ts">
import { watchEffect, onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { Locale } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
import aenUS from 'ant-design-vue/es/locale/en_US'
import azhCN from 'ant-design-vue/es/locale/zh_CN'
import { storeToRefs } from 'pinia'
import { useLanguageStore } from '@/stores/language'
import { useChannelAppStore } from '@/stores/channelApp'
import { isMobile } from '@/utils'
const languagestore = useLanguageStore()
const { locale } = storeToRefs(languagestore)
const channelAppStore = useChannelAppStore()

const appResize = (href: string) => {
    if (!isMobile() && import.meta.env.MODE !== 'project' && import.meta.env.MODE !== 'dev') {
        var locationNew = href.replace('mobile-prepress.', 'prepress.')
        window.location.replace(locationNew)
    }
}

watchEffect(() => {
    Locale.use(locale.value, locale.value === 'en-US' ? enUS : zhCN)
})

onMounted(() => {
    appResize(window?.location?.href ?? '')

    const hostname = window?.location?.hostname ?? ''
    const prefix = hostname.split('.')?.[0] ?? ''
    if (prefix) {
        channelAppStore.onload(prefix)
    } else {
        channelAppStore.setChannelApp({})
    }
})
</script>

<template>
    <a-config-provider :theme="{ token: { colorPrimary: '#00baab' } }" :locale="locale === 'en-US' ? aenUS : azhCN">
        <RouterView />
    </a-config-provider>
</template>

<style>
.van-nav-bar__title {
    font-weight: normal;
    font-size: 13px;
}
</style>
