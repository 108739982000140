export default {
    '1010102': 'Preflight',
    '1010103': 'Trapping',
    '1010104': 'Comparison',
    '1010105': 'Imposition',
    '1010106': 'RIP',
    '1010107': 'LenToTIFF',
    '1010108': 'Approval',
    '1010109': 'Smart',
    '1010110': 'Spotting',
    '1010111': 'Image Enhancement',
    '1010112': '3D Ray Tracing',
    '1010113': 'Color Conversion',
    '1010114': 'VDP',
    '1010115': 'Soft package order imposition',
    '1010116': 'Multi-page PDF split',
    '1010117': 'Cutting die typesetting',
    '1010118': 'Tiff precision conversion',
    '1010119': 'Auto bleed',
    '1010120': 'XML Imposition',
    '1010121': 'book Imposition',
    '1010122': 'Consistency Check',
    '1010151': 'Smart cutout',
    '1010153': 'image association',
    '1010154': 'Intelligent image expansion',
    '1010156': 'sketch',
    '1010157': 'Convert Text To Picture',
    '1010199': 'G2P',

    '00_原件': 'Original',
    '01_预检': 'Preflight',
    预检文件: 'preflight file',
    预检报告: 'Preflight report',
    '02_陷印': 'Trap',
    '03_对比': 'Compared',
    对比记录: 'Compare records',
    对比报告: 'Comparison report',
    '04_拼版': 'imposition',
    '05_RIP': 'RIP',
    '06_LenToTIFF': 'LenToTIFF',
    '07_审批': 'Approval',
    '08_智能柔印': 'Smart flexo printing',
    '09_混拼': 'mix',
    '10_画质增强': 'Image quality enhancement',
    '11_3D光线追踪': '3D ray tracing',
    '12_色彩转换': 'color conversion',
    '13_VDP': 'VDP',
    '14_软包订单拼版': 'Soft package order imposition',
    '16_多页PDF拆分': 'Multi-page PDF split',
    '17_刀模拼版': 'Cutting die typesetting',
    '18_Tiff精度转换': 'Tiff precision conversion',
    '19_自动出血': 'Auto bleed',
    '20_XML拼版': 'XML Imposition',
    '21_书刊拼版': 'book Imposition',
    '22_一致性检查': 'Consistency Check',
    '51_智能抠图': 'Smart cutout',
    '53_图像联想': 'image association',
    '54_智能扩图': 'Intelligent image expansion',
    '55_智能擦除': 'Smart erase',
    '56_线稿': 'Image to Line Draft ',
    '57_文生图': 'Convert Text To Picture',
    '99_G2P': 'G2P',

    '1010102.desc': 'Automatically detect file compliance and generate detailed reports.',
    '1010103.desc': 'Offset alignment errors to prevent unprinted gaps.',
    '1010104.desc': 'mparison reports.',
    '1010105.desc': 'Easily implement flexible collage and mixing of files.',
    '1010106.desc': 'Automatic color separation processing and personalized parameter setting.',
    '1010107.desc': 'Easily convert Len files to TIFF.',
    '1010108.desc': 'Support multi-channel CC confirmation, and save in the cloud.',
    '1010109.desc': 'Optimize the traditional flexographic printing process.',
    '1010110.desc': 'Easily achieve diversified file arrangement and combination.',
    '1010111.desc': 'Improve the image quality of prepress documents to ensure sharper print quality.',
    '1010112.desc': 'Hd light rendering of 3D images of prepress files.',
    '1010113.desc': 'Ensure color accuracy and consistency in printed matter.',
    '1010114.desc': 'Variable data enables personalization and customization of each print item.',
    '1010115.desc': 'Flexible packaging order and assembly automation.',
    '1010116.desc': 'Split each page of a PDF file into independent PDF files.',
    '1010117.desc': 'Complete the imposition according to the input die file.',
    '1010118.desc': 'The Tiff file precision value is converted according to the input parameters.',
    '1010119.desc': 'The label file automatically bleeds according to the input parameters.',
    '1010120.desc': 'Easily implement flexible collage and mixing of files.',
    '1010121.desc': 'Easily implement flexible collage and mixing of files.',
    '1010122.desc': 'Consistency Check',
    '1010151.desc': 'AI identifies the main entities in the image and completes one-click cutout.',
    '1010153.desc': 'Based on the input pictures, AI generates familiar pictures.',
    '1010154.desc': 'Intelligent expansion generates pictures based on input images.',
    '1010156.desc': 'Based on the input image, generate a line draft.',
    '1010157.desc': 'Generate a picture based on the input text',
    '1010199.desc': 'G2P',

    'program.StatusMap.create': 'create',
    'program.StatusMap.doing': 'doing',
    'program.StatusMap.failure': 'fail',
    'program.StatusMap.success': 'success',
    'program.StatusMap.timeout': 'timeout',
    'program.StatusMap.stop': 'Process termination',
    'program.StatusMap.audit_success': 'audit pass',
    'program.StatusMap.audit_failure': 'audit rejection',
    'program.StatusMap.send_success': 'send success'
}
