import { Env } from '@/enums/envEnums'
import { defaultOptions } from '@/libs/axios'

// A类地址：10.0.0.0-10.255.255.255，其中10.0.0.0是网络地址，10.255.255.255是广播地址。
// B类地址：172.16.0.0-172.31.255.255，其中172.16.0.0是网络地址，172.31.255.255是广播地址。
// C类地址：192.168.0.0-192.168.255.255，其中192.168.0.0是网络地址，192.168.255.255是广播地址。

export function setServerApi() {
    if (Env.server_host == undefined) {
        var host = window?.location.host
        var host_server = 'http://' + host
        var host_ws = 'ws://' + host + '/api/ws'
        if (window.location.protocol === 'https:') {
            host_server = 'https://' + host
            host_ws = 'wss://' + host + '/api/ws'
        }
        Env.server_host = host_server
        Env.url_api = host_server + import.meta.env.VITE_PATH_API
        Env.url_resource = host_server + import.meta.env.VITE_PATH_RESOURCE
        Env.url_ws = host_ws
        defaultOptions.baseURL = host_server + import.meta.env.VITE_PATH_API
    }

    if (import.meta.env.MODE == 'project' && window.location.protocol === 'http:') {
        var hostname = window?.location.hostname
        var cf_url = 'http://' + hostname + ':9090'
        Env.cf_url = cf_url
    }
}
